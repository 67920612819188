import React from 'react'
import Header from './Header'
import { Box } from '@mui/material'
import { withAuthGuard } from '../../hocs/with-auth-guard';
// import Footer from './Footer'

const Layout = withAuthGuard(({ children }) => {

    return (
        <>
            <Header />
            <Box
                component="main"
                sx={{ mx: 2 }}
            >
                {children}
            </Box>
            {/* <Footer /> */}
        </>
    )
})

export default Layout