import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, ListItem, ListItemIcon, ListItemText, Slide, SvgIcon, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { MuiOtpInput } from 'mui-one-time-password-input';
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import Lottie from 'lottie-react';
import successAnimation from "../../animations/success_animation.json"
import failedAnimation from "../../animations/failed_animation.json"
import { authPostRequest, postRequest, webGetRequest, webPostRequest } from '../../services/api-service';
import { getUserSubscriptionStatusUrl, loginByPhoneNumberUrl, payAppSubscriptionUrl, verifyPaymentDetailsUrl, verifyPhoneUrl } from '../../seed/url';
import { useAuth } from '../../hooks/use-auth';
import { PREMIUM } from '../../utils/constant';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PaymentDialog({
    open,
    handleClose,
    recordTracker,
}) {
    const { t } = useTranslation()
    const auth = useAuth();
    const authenticated = localStorage.getItem('authenticated') !== null;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const [step, setStep] = React.useState(authenticated ? 2 : 0)
    const [isLoading, setIsLoading] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [isPaynentSuccess, setIsPaynentSuccess] = React.useState(false);
    const [orderId, setOrderId] = React.useState('')

    const handleOTPChange = (newValue) => {
        setOtp(newValue)
    }

    const signInByPhoneNumber = async () => {
        setIsLoading(true)
        postRequest(
            loginByPhoneNumberUrl,
            {
                phone_no: phoneNumber,
                app_id: "vfjhcdfdfh13"
            },
            (data) => {
                localStorage.setItem('token', data.token);
                setStep(1)
                setIsLoading(false)
            },
            (error) => {
                setIsLoading(false)
            },
        )
    };

    const handleOTPComplete = async (value) => {
        setIsLoading(true)
        authPostRequest(
            verifyPhoneUrl,
            {
                phone_number: phoneNumber,
                otp: value
            },
            (data) => {
                localStorage.setItem('authenticated', 'true');
                webGetRequest(
                    getUserSubscriptionStatusUrl + auth?.user?.id,
                    (data) => {
                        if (data?.data?.account_type === PREMIUM) {
                            localStorage.setItem('subcribed', 'true');
                            handleClose()
                        } else {
                            localStorage.setItem('subcribed', 'false');
                        }
                        setStep(2)
                        setIsLoading(false)
                    },
                    (error) => {
                        setStep(2)
                        setIsLoading(false)
                    }
                )
            },
            (error) => {
                setIsLoading(false)
            },
        )
    }

    const payAppSubscription = () => {
        setIsLoading(true)
        webPostRequest(
            payAppSubscriptionUrl,
            {
                user_id: auth?.user?.id,
                payment_number: "0" + phoneNumber.toString().slice(-9),
                service_type: 2,
                service_id: 7,
                amount: 1000,
            },
            (data) => {
                if (data.data.resultcode === "000") {
                    verifyPaymentDetails(data.data.orderId)
                    setOrderId(data.data.orderId)
                    setStep(4)
                }
                setIsLoading(false)
            },
            (error) => {
                setIsLoading(false)
            }
        )
    }

    const verifyPaymentDetails = (orderId) => {
        setIsLoading(true)
        webGetRequest(
            verifyPaymentDetailsUrl + orderId,
            (data) => {
                if (data.error === false) {
                    setIsPaynentSuccess(true)
                    localStorage.setItem('subcribed', 'true');
                    recordTracker && recordTracker();
                } else {
                    setIsPaynentSuccess(false)
                    localStorage.setItem('subcribed', 'false');
                }
                setStep(5)
                setIsLoading(false)
            },
            (error) => {
                setIsLoading(false)
            }
        )
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="payment-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    disabled={isLoading}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <SvgIcon fontSize='small'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogContent>
                {(step === 0 || step === 3) &&
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <DialogContentText
                            id="payment-dialog"
                            color="text.primary"
                        >
                            {t(step === 0 ? "register_phone_description" : "pay_phone_description")} {phoneNumber}
                        </DialogContentText>
                        <PhoneInput
                            country={'tz'}
                            onlyCountries={['tz']}
                            value={phoneNumber}
                            onChange={phone => setPhoneNumber(phone)}
                        />
                        <Button
                            disabled={isLoading}
                            variant='contained'
                            fullWidth
                            onClick={() => {
                                if (step === 0) {
                                    signInByPhoneNumber()
                                } else {
                                    payAppSubscription()
                                }
                            }}
                        >
                            {t("continue")}
                        </Button>
                    </Box>
                }
                {step === 1 &&
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <DialogContentText
                            id="payment-dialog"
                            color="text.primary"
                        >
                            {t("otp_description")} {phoneNumber}
                        </DialogContentText>
                        <MuiOtpInput
                            value={otp}
                            onChange={handleOTPChange}
                            length={6}
                            onComplete={handleOTPComplete}
                            TextFieldsProps={{ size: 'small', placeholder: '-' }}
                            sx={{
                                display: "flex",
                                maxWidth: "520px",
                                marginInline: "auto",
                                gap: "6px",
                                my: 1,
                                ...(lgUp && {
                                    gap: "30px",
                                }),
                            }}
                        />
                    </Box>
                }
                {step === 2 &&
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <DialogContentText
                            id="payment-dialog"
                            color="text.primary"
                        >
                            {t("payment_description")}
                        </DialogContentText>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon
                                    fontSize="small"
                                    sx={{
                                        color: "primary.main",
                                    }}
                                >
                                    <CheckCircleIcon />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={t("weekly_subscription")} secondary="TZs 1,000/=" />
                        </ListItem>
                        <Button
                            disabled={isLoading}
                            variant='contained'
                            fullWidth
                            onClick={() => {
                                setStep(3)
                            }}
                        >
                            {t("pay")}
                        </Button>
                    </Box>
                }
                {step === 4 &&
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <DialogContentText
                            id="payment-dialog"
                            color="text.primary"
                            align='center'
                        >
                            {t("waiting_payment_from")} {phoneNumber}
                        </DialogContentText>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <CircularProgress
                                sx={{
                                    mx: 'auto',
                                }}
                            />
                        </Box>
                    </Box>
                }
                {step === 5 &&
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        {isPaynentSuccess &&
                            <Lottie
                                animationData={successAnimation}
                                loop={true}
                            />
                        }
                        {!isPaynentSuccess &&
                            <>
                                <Lottie
                                    animationData={failedAnimation}
                                    loop={true}
                                />
                                <Button
                                    disabled={isLoading}
                                    variant='contained'
                                    fullWidth
                                    onClick={() => {
                                        setStep(3)
                                    }}
                                >
                                    {t("try_again")}
                                </Button>
                                <Button
                                    disabled={isLoading}
                                    variant='contained'
                                    fullWidth
                                    onClick={() => {
                                        verifyPaymentDetails(orderId)
                                        setStep(4)
                                    }}
                                >
                                    {t("verify_payment")}
                                </Button>
                            </>
                        }
                    </Box>
                }
                <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Typography>{t("need_help")}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
                        <Typography>{t("contact_our")}</Typography>
                        <Typography
                            component={"a"}
                            href={`https://wa.me/255758784385?text=Habari AfyaChap technical support team`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ fontWeight: 900 }}
                        >
                            {t("support_team")}
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentDialog