import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const AuthGuard = (props) => {
  const { children } = props;
  let subcribed = localStorage.getItem('subcribed') === 'true'
  subcribed = localStorage.getItem('token') !== null;
  const ignore = useRef(false);
  const [checked, setChecked] = useState(false);

  useEffect(
    () => {
      if (ignore.current) {
        return;
      }

      ignore.current = true;

      if (!subcribed) {
        setChecked(true);
      } else {
        setChecked(true);
      }
    },
    [subcribed]
  );

  if (!checked) {
    return null;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
