import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material'
import React from 'react'

function ContentItemSkeleton() {

    return (
        <Grid container>
            <Grid item xs={11} sm={11} md={12} lg={12} xl={12}>
                <Card
                    sx={{
                        m: 2,
                        width: "100%"
                    }}
                >
                    <CardHeader
                        avatar={
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        }
                        title={
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                        }
                        subheader={
                            <Skeleton animation="wave" height={10} width="40%" />
                        }
                    />
                    <Skeleton sx={{ height: { xs: 190, sm: 200, md: 250, lg: 300, xl: 400 } }} animation="wave" variant="rectangular" />

                    <CardContent>
                        <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default ContentItemSkeleton