import { RouterProvider } from "react-router-dom";
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { router } from "./router";
import { createEmotionCache } from './utils/create-emotion-cache';
import { lightTheme } from './theme/light';
import { CssBaseline } from "@mui/material";
import { AuthConsumer, AuthProvider } from "./contexts/auth-context";
import Loader from "./components/Shared/Loader";

const clientSideEmotionCache = createEmotionCache();

const SplashScreen = () => <Loader />;

function App() {
  const light = lightTheme();

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <AuthProvider>
        <AuthConsumer>
          {(auth) => auth.isLoading ?
            <SplashScreen /> :
            <ThemeProvider theme={light}>
              <CssBaseline />
              <RouterProvider router={router} />
            </ThemeProvider>
          }
        </AuthConsumer>
      </AuthProvider>
    </CacheProvider>
  );
}

export default App;
