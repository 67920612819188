import React from 'react'
import { Box, Container } from '@mui/material'
import ContentItem from '../../components/Contents/ContentItem'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout/Layout';
import PaymentDialog from '../../components/Payments/PaymentDialog';

function SpecificContent() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true);
    const [content, setContent] = React.useState({});
    const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);

    const handleOpenPaymentDialog = () => {
        setOpenPaymentDialog(true)
    }

    const handleClosePaymentDialog = () => {
        setOpenPaymentDialog(false)
    }

    React.useEffect(() => {
        if (location?.state) {
            setContent(location.state)
            setIsLoading(false)
        } else {
            navigate("/");
        }
    }, [location, navigate])

    return (
        <Layout>
            {openPaymentDialog &&
                <PaymentDialog
                    open={openPaymentDialog}
                    handleClose={handleClosePaymentDialog}
                />
            }
            {!isLoading &&
                <>
                    <Container sx={{ mt: 3, mb: 10, display: { xs: 'none', md: 'block' } }}>
                        <ContentItem
                            content={content}
                            handleOpenPaymentDialog={handleOpenPaymentDialog}
                        />
                    </Container>
                    <Box sx={{ mt: 3, mb: 10, display: { xs: 'block', md: 'none' }, }}>
                        <ContentItem
                            content={content}
                            handleOpenPaymentDialog={handleOpenPaymentDialog}
                        />
                    </Box>
                </>
            }
        </Layout>
    )
}

export default SpecificContent