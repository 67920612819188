import React from 'react'
import { AppBar, Avatar, Box, Button, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, Toolbar, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@heroicons/react/24/outline/HomeIcon';

const drawerWidth = 240;
const navItems = [
    {
        label: 'home',
        route: '/',
        icon: <SvgIcon fontSize="small" sx={{ color: "white" }}><HomeIcon /></SvgIcon>
    },
];

function Header(props) {
    const { window } = props;
    const navigate = useNavigate();
    const { i18n } = useTranslation()
    const { t } = useTranslation();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("language", lng);
        handleClose();
    }

    const drawer = (
        <Box
            onClick={handleDrawerToggle}
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: 'center',
                background: "#0b6d36",
                height: "100%"
            }}
        >
            <Link to="/">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{ my: 2, ml: 2, color: "white" }}
                    >
                        AfyaChap
                    </Typography>
                </Box>
            </Link>
            <Divider />
            <List disablePadding sx={{ flex: 1 }}>
                {navItems.map((item, index) => (
                    <ListItemButton
                        key={index}
                        onClick={() => navigate(item.route)}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={t(item.label)} sx={{ color: "white" }} />
                    </ListItemButton>
                ))}
            </List>
            <Box sx={{ mb: 3 }}>
                <Typography sx={{ color: "white" }}>{t("need_help")}</Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
                    <Typography sx={{ color: "white" }}>{t("contact_our")}</Typography>
                    <Typography
                        component={"a"}
                        href={`https://wa.me/255758784385?text=Habari AfyaChap technical support team`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ fontWeight: 900 }}
                    >
                        {t("support_team")}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar
                component="nav"
                position="sticky"
                style={{
                    backdropFilter: 'blur(6px)',
                    backgroundColor: '#0b6d36',
                    top: 0,
                    zIndex: (theme) => theme.zIndex.appBar,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="text.primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <SvgIcon fontSize='large' sx={{ color: "white" }}>
                            <Bars3Icon />
                        </SvgIcon>
                    </IconButton>
                    <Link to="/" style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' }, color: "white" }}
                        >
                            AfyaChap
                        </Typography>
                    </Link>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item, index) => (
                            <Button
                                key={index}
                                sx={{ color: 'white', fontSize: 16 }}
                                onClick={() => navigate(item.route)}
                            >
                                {t(item.label)}
                            </Button>
                        ))}
                        <Typography
                            component={"a"}
                            href={`https://wa.me/255758784385?text=Habari AfyaChap technical support team`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "white", fontSize: 16, fontWeight: 600 }}
                        >
                            {t("contact_us")}
                        </Typography>
                    </Box>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="text.primary"
                    >
                        {i18n.language === "sw" &&
                            <Avatar
                                alt='Tanzania'
                                src='/assets/images/Tanzania.png'
                                sx={{ width: 30, height: 30 }}
                            />
                        }
                        {i18n.language === "en" &&
                            <Avatar
                                alt='British'
                                src='/assets/images/British.jpeg'
                                sx={{ width: 30, height: 30 }}
                            />
                        }
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleLanguageChange("sw")}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Avatar
                                    alt='Tanzania'
                                    src='/assets/images/Tanzania.png'
                                    sx={{ width: 30, height: 30 }}
                                />
                                <Typography sx={{ ml: 2 }}>Swahili</Typography>
                            </Box>
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange("en")}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Avatar
                                    alt='British'
                                    src='/assets/images/British.jpeg'
                                    sx={{ width: 30, height: 30 }}
                                />
                                <Typography sx={{ ml: 2 }}>English</Typography>
                            </Box>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </>
    )
}

export default Header