import { Box, CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { postRequest } from '../../services/api-service';
import ContentItem from './ContentItem';
import ContentItemSkeleton from './ContentItemSkeleton';
import PaymentDialog from '../Payments/PaymentDialog';

function ContentsList({ url, body, recordTracker }) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isFetching, setIsFetching] = React.useState(false);
    const [contents, setContents] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: []
    });
    const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);

    const handleOpenPaymentDialog = () => {
        setOpenPaymentDialog(true)
    }

    const handleClosePaymentDialog = () => {
        setOpenPaymentDialog(false)
    }

    const getContents = React.useCallback((page) => {
        postRequest(
            url,
            {
                ...body,
                limit: 4,
                page: page,
                sort: "updated_at desc"
            },
            (data) => {
                if (page === 1) {
                    setContents(data)
                } else if (page > 1) {
                    setContents((prev) => {
                        return {
                            ...prev,
                            page: data.page,
                            total_results: data.total_results,
                            total_pages: data.total_pages,
                            results: prev.results.concat(data.results)
                        }
                    })
                }
                setIsFetching(false);
                setIsLoading(false)
            },
            (error) => {
                setIsFetching(false);
                setIsLoading(false)
            },
        )
    }, [url, body])

    const handleScroll = React.useCallback(() => {
        // Check if the user has scrolled to the bottom of the page
        if (
            window.innerHeight + document.documentElement.scrollTop + 100 >=
            document.documentElement.offsetHeight
        ) {
            // Call the API when the user reaches the bottom
            if (!isFetching && contents.total_pages > contents.page) {
                setIsFetching(true);
                getContents(contents.page + 1);
            }
        }
    }, [getContents, contents, isFetching])

    React.useEffect(() => {
        // Add an event listener for the scroll event
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]); // Run effect once on mount

    React.useEffect(() => {
        getContents(1)
    }, [getContents])

    return (
        <>
            {openPaymentDialog &&
                <PaymentDialog
                    open={openPaymentDialog}
                    handleClose={handleClosePaymentDialog}
                    recordTracker={recordTracker}
                />
            }
            {(isLoading || contents.results.length === 0) &&
                <Grid container rowSpacing={6} marginBottom={isFetching ? 2 : 10}>
                    {["", "", "", ""].map((item, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={8} md={8} lg={7}>
                                <ContentItemSkeleton />
                            </Grid>
                        );
                    })}
                </Grid>
            }
            {!isLoading && contents.results.length > 0 &&
                <Grid container rowSpacing={6} marginBottom={isFetching ? 2 : 10}>
                    {contents.results.map((content, index) => {

                        return (
                            <React.Fragment key={index}>
                                {content?.content_files != null &&
                                    <Grid item xs={12} sm={8} md={8} lg={7}>
                                        <ContentItem
                                            content={content}
                                            handleOpenPaymentDialog={handleOpenPaymentDialog}
                                        />
                                    </Grid>
                                }
                            </React.Fragment>
                        );
                    })
                    }
                </Grid >
            }
            {
                isFetching &&
                <Grid container>
                    <Grid item xs={12} sm={8} md={8} lg={7}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 10,
                        }}>
                            <CircularProgress
                                sx={{
                                    mx: 'auto',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default ContentsList