import React from 'react'
import { Avatar, Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ContentDescription.css';
import { YES } from '../../utils/constant';

function ContentItem({ content, handleOpenPaymentDialog }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [readMore, setReadMore] = React.useState(false);

    return (
        <Card
            sx={{
                minWidth: 140,
                height: "100%",
                mr: 1,
            }}
        >
            <CardHeader
                avatar={
                    <Avatar
                        sx={{ height: 50, width: 50 }}
                        src={content.author_image}
                    />
                }
                title={content.author_name}
                subheader={
                    <Typography variant='caption'>Daktari kiganjani mwako</Typography>
                }
            />
            <Link
                state={content}
                onClick={() => {
                    const subcribed = localStorage.getItem('subcribed') === 'true'
                    if (content.is_package_free === YES || subcribed) {
                        navigate("/content")
                    } else {
                        handleOpenPaymentDialog()
                    }
                }}
            >
                <Carousel
                    indicators={content?.content_files.length > 1}
                    autoPlay={false}
                >
                    {content?.content_files.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {item.file_type === "VIDEO" ?
                                    <video
                                        width="100%"
                                        controls
                                        src={`${item.video_url}`}
                                    /> :
                                    <CardMedia
                                        component="img"
                                        image={item.file_url}
                                        alt="Picha ya Makala"
                                    />
                                }
                            </React.Fragment>
                        )
                    })}
                </Carousel>
            </Link>
            <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 800 }}>
                    {content.title}
                </Typography>
                {!readMore &&
                    <Typography variant="body2">
                        {content.short_description}
                        <span
                            style={{
                                fontStyle: "italic",
                                marginLeft: "6px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                const subcribed = localStorage.getItem('subcribed') === 'true'
                                if (content.is_package_free === YES || subcribed) {
                                    setReadMore(true)
                                } else {
                                    handleOpenPaymentDialog()
                                }
                            }}
                        >
                            ...{t("read_more")}
                        </span>
                    </Typography>
                }
                {readMore &&
                    <div
                        className="content-description"
                        dangerouslySetInnerHTML={{ __html: content.description }}
                    />
                }
            </CardContent>
        </Card>
    )
}

export default ContentItem