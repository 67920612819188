import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home/Home";
import SpecificContent from "../pages/SpecificContent/SpecificContent";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Home />,
    },
    {
        path: "/content",
        element: <SpecificContent />,
    },
]);