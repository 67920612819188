//LIVE SERVER BASE URLs
export const contentsUrl = "https://www.content.afyachap.com";
export const doctorsUrl = "https://www.doctors.afyachap.com";
export const usersUrl = "https://www.users.afyachap.com";
export const webUrl = "https://afyachap.com";

//TEST SERVER BASE URLs
// export const contentsUrl = "https://www.testserver.content.afyachap.com"
// export const doctorsUrl = "https://www.testserver.doctors.afyachap.com"
// export const usersUrl = "https://www.testserver.users.afyachap.com"

//LOCAL SERVER BASE URLs
// export const contentsUrl = "http://192.168.1.172:8004"
// export const doctorsUrl = "http://192.168.1.172:8001"
// export const usersUrl = "http://192.168.1.172:8003"

//USER MANAGEMENT URLs
export const getUserUrl = `${usersUrl}/api/get/user`
export const loginByPhoneNumberUrl = `${usersUrl}/api/register/user`
export const verifyPhoneUrl = `${usersUrl}/api/verify/phone`
export const addTrackIDUrl = `${usersUrl}/api/v1/add/track/id`

//CONTENT MANAGEMENT URLs
export const getAllContentUrl = `${contentsUrl}/api/v1/get/all/contents/by/pagination/web`;
export const getContentUrl = `${contentsUrl}/api/v1/get/all/contents/by/pagination/web`;
export const getAllContentBySubCategoryUrl = `${contentsUrl}/api/v1/view/content/by/category`;
export const getAllCategoriesUrl = `${contentsUrl}/api/v1/get/all/categories`;
export const getAllSubCategoriesUrl = `${contentsUrl}/api/v1/get/sub/categories/by/categoryid`;

//PAYMENT MANAGEMENT URLs
export const getUserSubscriptionStatusUrl = `${webUrl}/api/v1/get/users/account/status/`;
export const payAppSubscriptionUrl = `${webUrl}/api/v1/payment/selcom/push/ussd`;
export const verifyPaymentDetailsUrl = `${webUrl}/api/v1/afyachap/verify/payment/details/`;

//CONVERSION URLs
export const postbackUrl = "https://postback.level23.nl/?currency=USD&handler=11406&hash=222115b76e0b35dcae091645c5b140af&tracker=";
