import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslation from './locales/en.json'
import frTranslation from './locales/sw.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    sw: { translation: frTranslation },
  },
  lng: localStorage.getItem("language") != null ? localStorage.getItem("language") : 'sw',
  fallbackLng: 'sw',
  interpolation: {
    escapeValue: false,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
