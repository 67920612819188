import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { authGetRequest, webGetRequest } from '../services/api-service';
import { getUserSubscriptionStatusUrl, getUserUrl } from '../seed/url';
import { PREMIUM } from '../utils/constant';

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
};

const initialState = {
  isSubscriber: false,
  isLoading: true,
  user: null
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...(
        // if payload (user) is provided, then is authenticated
        user
          ? ({
            isSubscriber: true,
            isLoading: false,
            user
          })
          : ({
            isLoading: false
          })
      )
    };
  },
};

const reducer = (state, action) => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext({ undefined });

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;

    let isSubscriber = false;

    try {
      isSubscriber = localStorage.getItem('subcribed') === 'true';
      isSubscriber = localStorage.getItem('token') !== null;
    } catch (err) {
      console.error(err);
    }

    if (isSubscriber) {
      authGetRequest(
        getUserUrl,
        (data) => {
          if (data.error) {
            localStorage.clear();
            return;
          }
          webGetRequest(
            getUserSubscriptionStatusUrl + data.id,
            (data) => {
              if (data?.data?.account_type === PREMIUM) {
                localStorage.setItem('subcribed', 'true');
              } else {
                localStorage.setItem('subcribed', 'false');
              }
            },
            (error) => { }
          )
          dispatch({
            type: HANDLERS.INITIALIZE,
            payload: data
          });
        },
        () => {
          localStorage.clear();
        }
      )
    } else {
      dispatch({
        type: HANDLERS.INITIALIZE
      });
    }
  };

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);
